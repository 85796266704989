export function works(rest){
	const compornent = {
		data: function(){
			return {
				toggle: false,
				data:  [],
				detail: []
			}
		},
		created: function(){
            const $_this = this;
            axios.get(rest)
                .then(function (response) {
                    $_this.data = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
		},
		methods: {
			open : function(index){
				this.detail = this.data[index];
				this.toggle = true;
			},
			changing: function(){
				const body = document.getElementById('site-body');
				const head = document.getElementById('site-header');
				body.classList.toggle('hidden');
				head.classList.toggle('hidden');
			}
		},
		template:
		`
		<div>
			<ul class="listable">
				<li v-for="(items , index) in data">
					<div class="bg" :style="{ 'background-image': 'url('+items.acf.photo+')' }"></div>
                    <dl>
                        <dt v-if="items.acf.position == ''" v-html="items.title.rendered"></dt>
                        <dt v-else class="position" :data-position="items.acf.position" v-html="items.title.rendered"></dt>
                        <dd class="job" v-html="items.acf.kani"></dd>
                        <dd class="description" v-html="items.acf.mesage"></dd>
                    </dl>
				</li>
			</ul>
		</div>
		`
	}
	
	return compornent;
}